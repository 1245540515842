<template>
    <el-dialog 
    :visible="isVisible" title="Add New Blog" @close="closeDialog" width="35%">
        <div v-loading="loading">
            <div class="row">
                <div class="col-md-12 mb-2">
                    <label for="name">Banner Image</label><br />
                    <div class="mb-3">
                        <div v-if="bannerImage" class="position-relative banner-image-overlay">
                            <img :src="bannerImage" style="width: 100%; height: 100%; object-fit: cover;">
                            <div class="remove-img w-100 d-flex align-items-center justify-content-center"
                                style="height: 100%;">
                                <el-button class="remove-img-btn" icon="el-icon-delete"
                                    style="font-size: 1.5rem; color: #fff; background-color: rgba(0, 0, 0, 0.45)"
                                    @click="handleRemove">
                                </el-button>
                            </div>
                        </div>

                        <div v-else>
                            <input id="banner-image" type="file" class="d-none" accept=".jpg,.png,.jpeg"
                                @change="onImageChange" @click="onImageInputClick" />
                            <label for="banner-image" class="add-img">
                                <div class="w-100 d-flex align-items-center justify-content-center"
                                    style="height: 200px;">
                                    <span class="el-icon-plus"></span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mb-2">
                    <label for="name">Title</label><br />
                    <el-input placeholder="Title" v-model="title">
                    </el-input>
                </div>
                <div class="col-md-12 mt-2">
                    <label for="name">Subtitle</label><br />
                    <el-input placeholder="subtitle" v-model="subtitle">
                    </el-input>
                </div>
                <div class="col-md-12 mt-2">
                    <label for="name">Date</label><br />
                    <el-date-picker v-model="date" :format="'dd MMMM yyyy'" type="date" placeholder="Select date"
                        style="width: 100%">
                    </el-date-picker>
                </div>
                <div class="col-md-12 mt-2">
                    <label for="name">publisher</label><br />
                    <el-input placeholder="publisher" v-model="publisher">
                    </el-input>
                </div>
            </div>
            <div class="mt-3">
                <el-button type="primary" class="w-100" @click="onAddBlog">Add Blog</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            required: true,
        }
    },

    data() {
        return {
            loading: false,
            title: "",
            date: "",
            subtitle: "",
            publisher: "",
            bannerImage: "",
            selectedFile: null,
        };
    },

    methods: {
        closeDialog() {
            this.$emit("close-dialog");
        },

        clearInputs() {
            this.title = "";
            this.date = "";
            this.subtitle = "";
            this.publisher = "";
            this.bannerImage = "";
            this.selectedFile = null;
        },

        onImageInputClick(e) {
            e.target.value = "";
        },

        onImageChange(e) {
            e.preventDefault();
            const ALLOWED_FILE_EXTENSIONS = ["png", "jpg", "jpeg"];

            // Ensure the file type chosen is an image in the allowed file extensions
            this.selectedFile = e.target.files[0];
            const fileExtension = this.selectedFile.name
                ?.split(".")
                ?.pop()
                ?.toLowerCase();
            if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
                return this.showWarningMessage(
                    "Invalid file-Type",
                    `Only images of type ${ALLOWED_FILE_EXTENSIONS.toString()} are allowed`
                );
            }
            // Otherwise set the image url to show on the UI
            this.bannerImage = URL.createObjectURL(this.selectedFile);
        },
        
        handleRemove() {
            this.bannerImage = "";
            this.selectedFile = null;
        },

        validateInput() {
            if (this.title === "") {
                return this.showWarningMessage("Title required", "Please provide a Title for the Blog");
            }
            if (this.date === "") {
                return this.showWarningMessage("Date required", "Please provide a Date for the Blog");
            }
            if (this.subtitle === "") {
                return this.showWarningMessage("Subtitle required", "Please provide a Subtitle for the Blog");
            }
            if (this.publisher === "") {
                return this.showWarningMessage("Publisher required", "Please provide a Publisher for the Blog");
            }
            return true;
        },

        async requestUploadUrl() {
            const request = await this.httpRequest({
                method: "POST",
                url: "website/request-for-file-upload-url",
                loadingPropertyName: "loading",
                showSuccessMessage: false,
                body: {
                    file_name: this.selectedFile.name,
                    file_size: this.selectedFile.size,
                    reason: "NEWS_BLOG",
                },
            });
            if (!request) return;
            if (
                request.success &&
                request.message == "UPLOAD URL GENERATED SUCCESSFULLY"
            ) {
                return request.upload_url;
            }
        },

        async uploadPhotoToAWS() {
            let fullScreenLoader;
            try {
                fullScreenLoader = this.$loading({
                    lock: true,
                    text: "Uploading Image...",
                    spinner: "el-icon-loading",
                    background: "rgba(0%, 0%, 0%, 0.7)",
                });
                const awsUploadUrl = await this.requestUploadUrl()

                const formData = new FormData();
                Object.entries(awsUploadUrl.fields).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                formData.append("file", this.selectedFile);
                this.isUploadingFile = true;

                let request = await this.$http.post(`${awsUploadUrl.url}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: " ",
                    },
                });

                if (request.status == 204) {
                    return request.headers.location;
                } else throw "UNEXPECTED_RESPONSE";
            } catch (error) {
                if (error.message == "Network Error") {
                    this.isUploadingFile = false;
                    return this.showFailedMessage(
                        "Connection failed",
                        "A network error occurred, please try again."
                    );
                }
                this.isUploadingFile = false;
                return this.showFailedMessage(
                    "Upload Failed",
                    "Unable to Upload Photo now, Please try again"
                );
            }finally {
                fullScreenLoader.close();
            }
        },

        async onAddBlog() {
            if (this.validateInput() !== true) {
                return;
            }
            let imagePathUrl = null;
            if(this.selectedFile){
                const maxFileSizeMB = 2;
                if (this.selectedFile.size > maxFileSizeMB * 1024 * 1024) {
                    return this.showWarningMessage(
                        "File too large",
                        `File size should not exceed ${maxFileSizeMB}MB`
                    );
                }
                imagePathUrl = await this.uploadPhotoToAWS();
            }
            const request = await this.httpRequest({
                method: "POST",
                url: `website/blogs/add`,
                loadingPropertyName: "loading",
                showSuccessMessage: true,
                body: {
                    date: this.date,
                    title: this.title,
                    subtitle: this.subtitle,
                    publisher: this.publisher,
                    imageUrl: imagePathUrl,
                },
            });
            if (!request) return;
            if (
                request.success &&
                request.message == "Blog Item added successfully"
            ) {
                this.clearInputs();
                this.$emit("on-refresh");
                this.$emit("close-dialog");
            }
        },

    }


}

</script>

<style scoped>
* {
    box-sizing: border-box;
}
.remove-img {
    display: none;
}

.remove-img-btn {
    display: none;
}

.banner-image-overlay:hover .remove-img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgb(0, 0, 0, 0.45);
}

.banner-image-overlay:hover .remove-img-btn {
    display: block;
}

.add-img {
    border: 1px dashed rgb(189, 187, 187);
    border-radius: 5px;
    height: 80%;
    font-size: 3em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>