<template>
  <div class="container-fluid px-0 h-100" style="text-align: left">
    <article>
      <section style="padding-top: 50px">
        <div class="container">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <BreadCrumbComponent :pageTitles="['News & Blogs']" />
            </div>
          </div>

          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>News & Blogs </strong><br />
              </div>
            </div>
          </div>

          <div class="search_add_section">
            <div class="d-flex">
              <input
                type="text"
                v-model.trim="titleSearch"
                @input="onSearch"
                placeholder="Search by name"
                class="search_by_input border-color-fix"
                spellcheck="off"
              />

              <div style="margin-left: 20px">
                <el-select
                  v-model="selectedFilter"
                  @change="onSearch"
                  placeholder="Select"
                  class="d-block"
                  style="height: 40px !important"
                >
                  <el-option
                    v-for="item in filterOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="ml-3">
                <el-button
                  type="primary"
                  class="button-option"
                  @click="openAddBlogDialog"
                >
                  Add Blog Item
                </el-button>
              </div>
            </div>
          </div>
          <hr />

          <div v-loading="loading" class="table-responsive">
            <data-tables-server
              :data="blogs"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [10, 25, 50] }"
              type="expand"
              :total="totalBlogs"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              @query-change="onTableChange"
            >
              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getManyWebsiteBlogs"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Blogs Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No Blogs</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Date"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="170px"
              >
                <template slot-scope="scope">
                  <span>{{ formatDate(scope.row.date) }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Info"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <div><strong>Title</strong>: {{ scope.row.title }}</div>
                  <div><strong>Subtitle</strong>: {{ scope.row.subtitle }}</div>
                </template>
              </el-table-column>

              <el-table-column
                label="Publisher"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="160px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.publisher }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Is Published"
                :show-overflow-tooltip="true"
                label-class-name="text-left"
                width="130px"
              >
                <template slot-scope="scope">
                  <el-switch
                    :value="scope.row.is_published"
                    @change="togglePublishBlog(scope.row.website_blog_id)"
                    active-color="#13ce66"
                    inactive-color=""
                  >
                  </el-switch>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                label-class-name="text-left"
                width="80px"
              >
                <template slot-scope="scope">
                  <el-row
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <el-row style="display: flex; justify-content: center">
                      <el-dropdown trigger="click" @command="handleCommand">
                        <span class="el-dropdown-link">
                          <el-button
                            type="primary"
                            icon="el-icon-more"
                            circle
                            style="margin-right: 10px"
                          >
                          </el-button>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            :command="`edit#${scope.row.website_blog_id}`"
                          >
                            <span class="drop-down-items">
                              <i class="el-icon-edit"></i> Edit Item
                            </span>
                          </el-dropdown-item>

                          <el-dropdown-item
                            :command="`manage-content#${scope.row.website_blog_id}`"
                          >
                            <span class="drop-down-items">
                              <i class="el-icon-s-order"></i> Manage Content
                            </span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="`preview-content#${scope.row.website_blog_id}`"
                          >
                            <span class="drop-down-items">
                              <i class="el-icon-view"></i> Preview News Item
                            </span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            divided
                            :command="`delete#${scope.row.website_blog_id}`"
                          >
                            <span class="drop-down-items text-danger">
                              <i class="el-icon-delete"></i> Delete Item
                            </span>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-row>
                  </el-row>
                </template>
              </el-table-column>
            </data-tables-server>
          </div>
        </div>
      </section>

      <AddBlogDialog
        :is-visible="showAddBlogDialog"
        @on-refresh="getManyWebsiteBlogs"
        @close-dialog="closeAddBlogDialog"
      />

      <EditBlogDialog
        :is-visible="showEditBlogDialog"
        :website-blog-id="websiteBlogId"
        @on-refresh="getManyWebsiteBlogs"
        @close-dialog="closeEditBlogDialog"
      />
    </article>
  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import debounce from "debounce";
import { format, parseISO } from "date-fns";
import AddBlogDialog from "@/components/dialogs/add-blog-dialog.vue";
import EditBlogDialog from "@/components/dialogs/edit-blog-dialog.vue";

export default {
  components: {
    ScaleOut,
    BreadCrumbComponent,
    AddBlogDialog,
    EditBlogDialog,
  },

  data() {
    return {
      loading: false,
      loadingError: false,
      titleSearch: "",
      blogs: [],

      totalBlogs: 0,
      currentPage: 1,
      pageSize: 10,

      tableProps: {
        border: true,
        stripe: true,
      },
      showAddBlogDialog: false,
      showEditBlogDialog: false,
      websiteBlogId: "",
      selectedFilter: "",
      filterOptions: [
        {
          label: "Show All",
          value: "",
        },
        {
          label: "Only Published",
          value: "PUBLISHED",
        },
        {
          label: "Only Unpublished",
          value: "UNPUBLISHED",
        },
      ],
    };
  },
  mounted() {
    this.getManyWebsiteBlogs();
  },

  methods: {
    onSearch: debounce(function () {
      this.getManyWebsiteBlogs();
    }, 500),

    formatDate(dateIso) {
      return format(parseISO(dateIso), "do MMM yyyy hh:mma");
    },

    async onTableChange(queryInfo) {
      if (queryInfo) {
        if (
          queryInfo.type == "page" ||
          queryInfo.type == "size" /* ||
              queryInfo.type == "filter" */
        ) {
          this.currentPage = queryInfo.page;
          this.pageSize = queryInfo.pageSize;
          this.getManyWebsiteBlogs();
        }
      }
    },

    async getManyWebsiteBlogs() {
      let request = await this.httpRequest({
        method: "GET",
        url: `website/blogs/fetch-many?page=${this.currentPage}&pageSize=${this.pageSize}&titleSearch=${this.titleSearch}&showOnly=${this.selectedFilter}`,
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "loadingError",
        showSuccessMessage: false,
      });

      if (
        request &&
        request.success &&
        request.message == "Blogs fetched successfully"
      ) {
        this.blogs = request.data;
        this.totalBlogs = this.blogs.length;
      }
    },

    openAddBlogDialog() {
      this.showAddBlogDialog = true;
    },

    closeAddBlogDialog() {
      this.showAddBlogDialog = false;
    },

    closeEditBlogDialog() {
      this.showEditBlogDialog = false;
    },

    async togglePublishBlog(blogId) {
      let request = await this.httpRequest({
        method: "PATCH",
        url: `website/blogs/toggle-publish/${blogId}`,
        loadingPropertyName: "loading",
        showSuccessMessage: true,
      });

      if (
        request &&
        request.success &&
        request.message == "Blog Item published status changed successfully"
      ) {
        this.getManyWebsiteBlogs();
      }
    },

    async onDelete(blogId) {
      await this.$confirm(
        "This will permanently delete this Blog. Do you want to continue?",
        "Confirm delete",
        {
          // eslint-disable-line no-unreachable
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      let request = await this.httpRequest({
        method: "DELETE",
        url: `website/blogs/delete-one/${blogId}`,
        loadingPropertyName: "loading",
        showSuccessMessage: true,
      });

      if (
        request &&
        request.success &&
        request.message == "Blog item deleted successfully"
      ) {
        this.getManyWebsiteBlogs();
      }
    },

    handleCommand(command) {
      const commandEventFired = command.split("#");
      const commandName = commandEventFired[0];
      const websiteBlogId = commandEventFired[1];
      if (commandName === "edit") {
        this.websiteBlogId = websiteBlogId;
        this.showEditBlogDialog = true;
      }
      if (commandName === "delete") {
        this.onDelete(websiteBlogId);
      }
      if (commandName === "manage-content") {
        this.$router.push({
          name: "NewsContent",
          params: { websiteBlogId: websiteBlogId },
        });
      }
      if (commandName === "preview-content") {
        const newsBlogItem = this.blogs.find(
          (blog) => blog.website_blog_id === websiteBlogId
        );

        //Redirect the user to a new tab
        window.open(
          `${process.env.VUE_APP_WEBSITE_BASE_URL}/news-blog/${newsBlogItem.slug}`,
          "_blank"
        );
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }

  .addbtn {
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .search_add_section {
    display: block;
  }

  .addbtn {
    width: 100%;
    margin-top: 10px;
  }

  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.course_request_form .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.course_request_form .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.course_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .course_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .course_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .course_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .course_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
